const tailwind = require('../tailwind');

module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "/portfolio"

  siteTitle: 'Declan Keyes-Bevan - Software Developer', // Navigation and Site Title
  siteTitleAlt: 'Declan Keyes-Bevan', // Alternative Site title for SEO
  siteTitleShort: 'Declan Keyes-Bevan', // short_name for manifest
  siteHeadline: 'Building automated Cloudy things', // Headline for schema.org JSONLD
  siteUrl: 'https://www.declankeyesbevan.com', // Domain of your site. No trailing slash!
  siteLanguage: 'en', // Language Tag on <html> element
  siteLogo: '/logo.png', // Used for SEO and manifest
  siteDescription: 'Playful & Colorful One-Page website with Parallax effect',
  author: 'Declan Keyes-Bevan', // Author for schema.org JSONLD

  // Manifest and Progress color
  themeColor: tailwind.colors.orange,
  backgroundColor: tailwind.colors.blue,
};
