import React from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import { Parallax } from 'react-spring/renderprops-addons.cjs';
import { IoLogoGithub, IoLogoTwitter } from 'react-icons/io';
import { FaMediumM } from 'react-icons/fa';
import { GrLinkedinOption } from 'react-icons/gr';

// Components
import Layout from '../components/Layout';
import ProjectCard from '../components/ProjectCard';

// Elements
import Inner from '../elements/Inner';
import { Title, BigTitle, Subtitle } from '../elements/Titles';

// Views
import Hero from '../views/Hero';
import Projects from '../views/Projects';
import About from '../views/About';
import Contact from '../views/Contact';

import avatar from '../images/avatar.jpg';
// import linkedin from '../images/linkedin.png';

const ProjectsWrapper = styled.div`
  ${tw`flex flex-wrap justify-between mt-8`};
  display: grid;
  grid-gap: 4rem;
  grid-template-columns: repeat(2, 1fr);
  @media (max-width: 1200px) {
    grid-gap: 3rem;
  }
  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    grid-gap: 2rem;
  }
`;

const AboutHero = styled.div`
  ${tw`flex flex-col lg:flex-row items-center mt-8`};
`;

const Avatar = styled.img`
  ${tw`rounded-full w-32 xl:w-48 shadow-lg h-auto`};
`;

// const LinkedIn = styled.img`
//   ${tw`rounded-full w-32 xl:w-48 shadow-lg h-auto`};
// `;

const AboutSub = styled.span`
  ${tw`text-white pt-12 lg:pt-0 lg:pl-12 text-2xl lg:text-3xl xl:text-4xl`};
`;

const AboutDesc = styled.p`
  ${tw`text-grey-light text-lg md:text-xl lg:text-2xl font-sans pt-6 md:pt-12 text-justify`};
`;

const ContactText = styled.p`
  ${tw`text-grey-light font-sans text-xl md:text-2xl lg:text-3xl`};
`;

const Footer = styled.footer`
  ${tw`text-center text-grey absolute pin-b p-6 font-sans text-md lg:text-lg`};
`;

const Index = () => (
  <>
    <Layout/>
    <Parallax pages={3}>
      <Hero offset={0}>
        <BigTitle>
          Hello, <br/> I'm Declan Keyes-Bevan.
        </BigTitle>
        <Subtitle>
          I'm a software developer who builds automated Cloudy things on Amazon
          Web Services and Google Cloud Platform using Python and JavaScript.
        </Subtitle>
      </Hero>
      <About offset={1}>
        <Title>About</Title>
        <AboutHero>
          <Avatar src={avatar} alt="Declan Keyes-Bevan"/>
          <AboutSub>
            Writer of code. Solver of problems.
          </AboutSub>
        </AboutHero>
        <AboutDesc>
          <p>
            Senior Software Developer with 8 years in Back-end, Cloud and Automation.
          </p>
          <p>
            Experience working on short engagements in small companies through to public enterprises delivering multi-million dollar, business transformation projects.
          </p>
          <p>
            Deep Python, Amazon Web Services and architectural skills from years of wrangling computers.
          </p>
          <p>
            Designed, developed and operated Cloud Native, Serverless applications and the Continuous Integration/Deployment pipelines to manage them using AWS.
          </p>
          <p>
            Solves business problems. Likes to code.
          </p>
        </AboutDesc>
      </About>
      <Contact offset={2}>
        <Inner>
          <Title>Get in touch</Title>
          <ContactText>
            <a href="https://au.linkedin.com/in/declankeyesbevan" target="_blank" rel="noopener noreferer">
              <GrLinkedinOption size="1.2em" />
            </a>
            {" "}
            <a href="https://github.com/declankeyesbevan" target="_blank" rel="noopener noreferer">
              <IoLogoGithub size="1.2em" />
            </a>
            {" "}
            <a href="https://medium.com/@declankeyesbevan" target="_blank" rel="noopener noreferer">
              <FaMediumM size="1.2em" />
            </a>
            <br />
          </ContactText>
        </Inner>
      </Contact>
    </Parallax>
  </>
);

export default Index;
